import React from 'react'
import img2 from "../Assets/d.jpg";
import img3 from "../Assets/dhl.jpg";
import img4 from "../Assets/evri.jpg";
import img5 from "../Assets/exp.jpg";
import img6 from "../Assets/fedex.jpg";
import img7 from "../Assets/fez.jpg";
import img8 from "../Assets/gig.jpg";
import img9 from "../Assets/kwik.jpg";
import img0 from "../Assets/m.jpg";
import img1 from "../Assets/box.jpg";
import imw from "../Assets/shopify_glyph.png"
import img11 from '../Assets/LONE-STAR.jpg'
import img12 from '../Assets/paystack.png'
const logos =[img0, img1, img2, img3, img4, img5, img6, img7, img8, img9, imw, img11, img12];

const LogoGrid = () => {
  return (
    <div className="logo-grid">
        {logos.map((logo, index)=> (

        <div key={index} className="logo-container">
            <div className="logo-image">
                <img src={logo} alt={`Logo ${index +1}`} />
            </div>
        </div>
    ))}
    </div>
  )
}

export default LogoGrid;