import React from 'react'
import CollapsibleText from './Question'
const FAQ = () => {
  return (
    <div>
      <div>
      <div>
        <h2 className='faq-heading'>
          Frequently Asked Questions 
          </h2>
          <h4 className='faq-subheading'>Get answers to commonly asked questions about SAG Logistics, our services and others.</h4>
          
    </div>
    <div>
      
      <CollapsibleText/> 
    </div>
    </div>
    </div>
    
  )
}

export default FAQ