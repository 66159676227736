import React, { useState } from 'react'
import emailjs from '@emailjs/browser';

const Modal = ({ isOpen, onClose }) => {
    const [fullName, setFullName] = useState('');
    const [fullName2, setFullName2] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumber2, setPhoneNumber2] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();

    const serviceId = 'service_r0i7rjr';
    const templateId = 'template_7rjosoe';
    const publicKey = 'y4olKfzplLekR-sPY';

    const templateParams = {
        sender_name :fullName,
        sender_number: phoneNumber,
        sender_address: address,
        receiver_name :fullName2,
        receiver_number: phoneNumber2,
        receiver_address: address2,
        to_name: 'SAG logistics',
        
    };
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        alert('Order sent');
        setFullName('');
        setFullName2('');
        setPhoneNumber('');
        setPhoneNumber2('');
        setAddress('');
        setAddress2('');
        
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  
    }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        
        <h3 className='details'>Delivery details</h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Full-Name:
              <input type="text" name="name" 
              placeholder='Full Name'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required />
            </label>
          </div>
          <div >
            <label>
              Phone number:
              <input type="tel" name="phone-number" 
              placeholder='Phone Number'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required />
            </label>
          </div>
          <div className="form-form">
            <label>
              Address:
              <input type='text' name="address" 
              placeholder='Address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required />
            </label>
          </div>
          <br/>
          <h3 className='details'>
            Collection details
          </h3>
          <div>
            <label>
              Full-Name:
              <input type="text" name="name"
              placeholder='Full Name'
              value={fullName2}
              onChange={(e) => setFullName2(e.target.value)}
              required />
            </label>
          </div>
          <div >
            <label>
              Phone number:
              <input type="tel" name="phone-number"
              placeholder='Phone Number'
              value={phoneNumber2}
              onChange={(e) => setPhoneNumber2(e.target.value)}
              required />
            </label>
          </div>
          <div className="form-form">
            <label>
              Address:
              <input type='text' name="address"
              placeholder='Address'
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              required />
            </label>
          </div>
          <button type="submit" id='but'>Submit</button>
          <button type="button" id='but' onClick={onClose}>Close</button>
        </form>
      </div>
    </div>
  );
};

export default Modal;