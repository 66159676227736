import React from 'react'

const Bottom = () => {
  return (
    <div className='copyright'>
        <p> 
            Copyright© SAG logistics 2024 | All rights reserved
        </p>
    </div>
  )
}

export default Bottom