import React from 'react'
import Question from '../Assets/question-mark.svg'

const QandA = () => {
  return (
    <div>
    <div className='wrapper'>
        <div className='collapsible'>
            <input type='checkbox' id='collapsible-head'/>
            <div className='flex-this'>
            <label htmlFor='collapsible-head' >
                <img src={Question} className='question' alt='question mark' />
            How much will my delivery cost? 
             
            </label>

            </div>
            
            <div className='collapsible-text'>
                <p>
                    Pricing for delivery depends on the
                    courier service used for a particular delivery. 
                    Some services will charge according to the weight of the packages being delivered, 
                    while some others will charge according to the distance between pickup and delivery locations. 
                    You can use our free online
                    delivery calculator to estimate the cost of your deliveries.
                </p>
            </div>
        </div>
    </div>
    <div className='wrapper'>
        <div className='collapsible2'>
            <input type='checkbox' id='collapsible-head2'/>
            <label htmlFor='collapsible-head2' >
            <img src={Question} className='question' alt='question mark' />
            How does SAG Logistics work? 
            </label>
            
            <div className='collapsible-text2'>
                <p>
                SAG Logistics is a delivery aggregator.
                We partner with multiple local and international couriers.
                When you make a request, SAG Logistics sends the information directly to the delivery partner of your choice,
                who executes the delivery within the agreed timeline
                </p>
            </div>
        </div>
    </div>
    <div className='wrapper'>
        <div className='collapsible3'>
            <input type='checkbox' id='collapsible-head3'/>
            <label htmlFor='collapsible-head3' >
            <img src={Question} className='question' alt='question mark'/>
            Do I get a refund if my package is not delivered?
            </label>
            
            <div className='collapsible-text3'>
                <p>
                With SAG Logistics, you always stay in control of your deliveries.
                You're always guaranteed a refund if your delivery is not completed. 
                Once a delivery is arranged, you have the option to cancel until the package is picked up. 
                Upon cancellation of any delivery,
                funds are immediately reversed to your wallet and can be applied towards other deliveries
                </p>
            </div>
        </div>
    </div>
    <div className='wrapper'>
        <div className='collapsible4'>
            <input type='checkbox' id='collapsible-head4'/>
            <label htmlFor='collapsible-head4' >
            <img src={Question} className='question'alt='question mark' />
            How do I get lower delivery rates? 
            </label>
            
            <div className='collapsible-text4'>
                <p>
                You don't have to do anything to get lower delivery rates on SAG Logistics. 
                Our delivery rates are automatically cheaper across the board. 
                Sign up for free and start shipping to enjoy these lower rates!
                </p>
            </div>
        </div>
    </div>
    <div className='wrapper'>
        <div className='collapsible5'>
            <input type='checkbox' id='collapsible-head5'/>
            <label htmlFor='collapsible-head5' >
            <img src={Question} className='question'alt='question mark' />
            How can I arrange a delivery? 
            </label>
            
            <div className='collapsible-text5'>
                <p>
                To arrange a delivery, click book now and enter your shipping details. 
                An account would be automatically setup for you. 
                Enter your delivery address and further details on the items in your package, 
                your order will be generated, and other details will be completed via email.
                </p>
            </div>
        </div>
    </div>
    </div>
  )
}

export default QandA