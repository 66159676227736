import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
const ContactForm = () => {
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

const serviceId = 'service_r0i7rjr';
const templateId = 'template_9orsj6a';
const publicKey = 'y4olKfzplLekR-sPY';

const templateParams = {
  from_name:  `${firstName} ${lastName}`,
  from_email: email,
  to_name: 'SAG logistics',
  message: message,
};

emailjs.send(serviceId, templateId, templateParams, publicKey)
.then((response) => {
  console.log('Email sent successfully!', response);
  alert('Message sent, we will get back to you shortly');
  setFirstName('');
  setLastName('');
  setEmail('');
  setMessage('');
})
.catch((error) => {
  console.error('Error sending email:', error);
});

  const validateForm = () => {
    const newErrors = {};
    if (!setFirstName) newErrors.firstName = 'First Name is required';
    if (!setLastName) newErrors.lastName = 'Last Name is required';
    if (!setEmail) {
      newErrors.email = 'Email is required';
    } 
    if (!setMessage) newErrors.message = 'Message is required';
    return newErrors;
  };

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Here you can handle the form submission (e.g., send to an API)
      console.log('Form submitted:');
      setSubmitted(true);
      setErrors({});
    }
  };
return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>
      {submitted && <p className="success-message">Thank you for your message!</p>}
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
            <input
              type='text'
              placeholder='First Name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={errors.firstName ? 'error' : ''}
            />
          
          {errors.firstName && <span className="error-message">{errors.firstName}</span>}
        </div>
        <div className="form-group">
            <input
              type='text'
              placeholder='Last Name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={errors.lastName ? 'error' : ''}
            />
          
          {errors.lastName && <span className="error-message">{errors.lastName}</span>}
        </div>
        <div className="form-group">
          
            <input
             type='email'
             placeholder='Email'
             value={email}
             onChange={(e) => setEmail(e.target.value)}
              className={errors.email ? 'error' : ''}
            />
          
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>
        <div className="form-group">
          
            <textarea
            placeholder='Message'
              cols='30'
              // rows='7'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={errors.message ? 'error' : ''}
            />
          {errors.message && <span className="error-message">{errors.message}</span>}
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;