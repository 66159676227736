import React, { useState } from 'react';
import image2 from '../Assets/ecommerce.jpg'
import Modal from './Modal';
const Ecom = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };
  return (
    <div className="ecom-background">
        
    <div className="box-picture">
        <img src={image2} alt="ecommerce"/>
    </div>
    <div className="ecom-text">
      <h1>Logistics for e-Commerce</h1>
      <p>Make seamless deliveries to your customers worldwide </p>
      <button onClick={openModal} className='butt' >Book now</button>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  </div>
  )
}

export default Ecom